import React from 'react';
import {PayMethod} from './recharge.service';
import {useTranslation} from 'react-i18next';
import Icons from './icons';
import {Image} from 'antd-mobile';
import {toPriceStr} from '@/utils';

export interface RechargeChannelProps {
  payMethodList: PayMethod[];
  payMethodId?: number;
  onPayMethodChange: (id: number) => void;
}

const RechargeChannel: React.FC<RechargeChannelProps> = ({
  payMethodList,
  payMethodId,
  onPayMethodChange,
}) => {
  const {i18n} = useTranslation();
  const hasDesc = !!payMethodList.find(v => v.id === payMethodId)
    ?.activeDescription;
  const titles = hasDesc ? ['channel', 'instructions'] : ['channel'];
  const getDesc = () => {
    if (!hasDesc) return;
    const desc = payMethodList.find(
      v => v.id === payMethodId,
    )?.activeDescription;
    return desc?.split('\n');
  };

  return (
    <div className="flex flex-col gap-2">
      {titles.map(title => (
        <div key={title} className="bg-card rounded-lg p-3 flex flex-col gap-2">
          <div className="text-t1 text-sm">
            {i18n.t(`recharge-page.label.${title}`)}
          </div>
          {title === 'channel' ? (
            <>
              {payMethodList.map(item => (
                <div
                  key={item.id}
                  onClick={() => onPayMethodChange(item.id)}
                  className={`border bg-[var(--button-s)] rounded-lg p-3 flex flex-row justify-between items-center ${
                    item.id === payMethodId
                      ? 'border-c1'
                      : 'border-[rgba(0,0,0,0)]'
                  }`}>
                  <div className="flex flex-col gap-1 items-start">
                    <Image src={item.payIcon} height={'1.5rem'} fit="contain" />
                    <p className="text-t3 text-xs font-bold">{`${toPriceStr(
                      item.minAmount,
                      {
                        fixed: 2,
                        showCurrency: true,
                        thousands: true,
                        spacing: true,
                      },
                    )} - ${toPriceStr(item.maxAmount, {
                      fixed: 2,
                      showCurrency: true,
                      thousands: true,
                      spacing: true,
                    })}`}</p>
                  </div>
                  {item.id === payMethodId && (
                    <div className="w-[0.875rem] h-[0.875rem] bg-c1 rounded-full flex items-center justify-center">
                      <Icons.Select />
                    </div>
                  )}
                </div>
              ))}
            </>
          ) : (
            <>
              {getDesc()?.map(desc => (
                <div key={desc} className="flex flex-row justify-between">
                  <p className="w-1 h-1 rounded-full bg-[var(--T2)] relative top-[0.375rem] left-2" />
                  <p className="text-t2 text-xs w-[19.25rem]">{desc}</p>
                </div>
              ))}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default RechargeChannel;
