import React from 'react';

const Select = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="9"
    height="9"
    viewBox="0 0 9 9"
    fill="none">
    <path
      d="M1.61898 4.16667L3.5449 6.24074L7.28564 2.5"
      stroke="white"
      strokeWidth="1.33333"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default {
  Select,
};
