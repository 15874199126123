import React from 'react';
import {View, Image} from 'react-native';
import theme from '@/style';
import {toPriceStr} from '@/components/utils';
import globalStore from '@/services/global.state';
// import LazyImage from '@/components/basic/image';
import {useInnerStyle} from './recharge.hooks';
import DarkRefresh from '../svg/dark-refresh';
import {useTranslation} from 'react-i18next';
// const rightIcon = require('@components/assets/icons/chevron-right.png');
export interface RechargeBalanceProps {
  balance?: number;
  payMethod?: string;
  tip?: string;
  onRefresh?: () => void;
  // onGotoRecords?: () => void;
}

const RechargeBalance: React.FC<RechargeBalanceProps> = props => {
  const {i18n} = useTranslation();
  const {
    balance = 0,
    payMethod = '',
    onRefresh = () => {},
    // onGotoRecords,
    tip = i18n.t('recharge-page.tip.payMethodTip'),
  } = props;
  const {
    // size: {rightIconSize},
    balanceStyles: styles,
  } = useInnerStyle();
  return (
    <View style={[theme.margin.topl, styles.bgBox, styles.container]}>
      <Image
        source={require('@assets/imgs/recharge-header-bg.webp')}
        style={[styles.cardBg, styles.bgBox]}
      />
      <View style={[theme.flex.between, theme.flex.flex1]}>
        <View
          style={[styles.balanceContainer, theme.flex.row, theme.flex.between]}>
          <View style={[theme.flex.flex1]}>
            <div className="text-t1 text-xs">
              {i18n.t('me.money.totalBalance')}
            </div>
            <View style={[theme.flex.row, theme.flex.alignEnd]}>
              <div className="font-din text-t1 text-2xl font-bold">
                {toPriceStr(balance, {
                  thousands: true,
                  spacing: true,
                  currency: globalStore.currency,
                })}
              </div>
              {/* <div onClick={onRefresh} className="ml-[0.625rem]">
                <DarkRefresh />
              </div> */}
            </View>
          </View>
          <div onClick={onRefresh} className="ml-[0.625rem]">
            <DarkRefresh />
          </div>
          {/* <div
            className="flex flex-row gap-2 items-center"
            onClick={onGotoRecords}>
            <div className="flex flex-col items-end">
              <span className="text-t1 text-xs">
                {i18n.t('label.recharge')}
              </span>
              <span className="text-t1 text-xs">
                {i18n.t('recharge-page.label.records')}
              </span>
            </div>
            <LazyImage
              occupancy={'transparent'}
              imageUrl={rightIcon}
              width={rightIconSize}
              height={rightIconSize}
            />
          </div> */}
        </View>
        <div className="bg-[rgba(0,0,0,0.3)] px-5 py-2">
          <p className="text-t1 text-xs">
            {i18n.t('recharge-page.currentMethod')}:
            <span className="ml-1">{payMethod}</span>
          </p>
          <div className="mt-1">
            <span className="text-t1 text-xs opacity-[0.7]">{tip}</span>
          </div>
        </div>
      </View>
    </View>
  );
};

export default RechargeBalance;
