import {useScreenSize} from '@/common-pages/hooks/size.hooks';
import globalStore from '@/services/global.state';
import theme from '@/style';
import {BasicObject} from '@/types';
import {useMemo} from 'react';
import {StyleSheet} from 'react-native';

function useSize() {
  const {screenWidth, screenHeight, designWidth} = useScreenSize();
  const rightIconSize = (14 * screenWidth) / designWidth;
  const itemWidth = (72 * screenWidth) / designWidth;
  const itemHeight = (40 * screenWidth) / designWidth;
  const size = useMemo(() => {
    return {
      screenWidth,
      screenHeight,
      designWidth,
      rightIconSize,
      itemWidth,
      itemHeight,
    };
  }, [
    screenWidth,
    screenHeight,
    designWidth,
    rightIconSize,
    itemWidth,
    itemHeight,
  ]);
  return size;
}

export function useInnerStyle() {
  const size = useSize();

  const amountStyle = StyleSheet.create({
    opacity: {
      opacity: 0.7,
    },
  });

  const baseInputStyle = {
    minHeight: 48,
    maxHeight: 48,
    height: 48,
    padding: 0,
    ...theme.font.main,
    ...theme.font.fm,
  } as BasicObject;

  const inputStyle = globalStore.isWeb
    ? {
        ...baseInputStyle,
        outlineWidth: '0',
        caretColor: theme.fontColor.main,
      }
    : baseInputStyle;

  const inputStyles = StyleSheet.create({
    inputContainer: {
      borderBottomWidth: 0,
    },
    container: {
      paddingBottom: 0,
    },
    error: {
      margin: 0,
      height: 0,
    },
  });

  const selectStyles = StyleSheet.create({
    item: {
      width: size.itemWidth,
      height: size.itemHeight,
    },
    itemMargin: {
      marginLeft:
        (size.screenWidth - theme.paddingSize.l * 4 - size.itemWidth * 4 - 1) /
        3,
    },
    inputWrap: {
      borderWidth: 1,
      borderColor: theme.backgroundColor.grey,
    },
  });

  const balanceStyles = StyleSheet.create({
    cardBg: {
      position: 'absolute',
    },
    container: {
      borderRadius: 8,
      overflow: 'hidden',
    },
    bgBox: {
      width: size.screenWidth - theme.paddingSize.l * 2,
      height: (459 / 1053) * (size.screenWidth - theme.paddingSize.l * 2),
    },
    balanceContainer: {
      paddingHorizontal: 20,
      paddingTop: 20,
      alignItems: 'center',
    },
    methodContainer: {
      backgroundColor: 'rgba(0,0,0,0.3)',
      paddingHorizontal: 20,
    },
  });

  return {
    size,
    amountStyle,
    inputStyle,
    inputStyles,
    selectStyles,
    balanceStyles,
  };
}
